import React from 'react';
import styles from './IntegralIndicators.module.css';
import MiscUtils, { isZeroValue } from 'utils/MiscUtils';
import { RowItem } from 'utils/types';

interface IntegralIndicatorsProps {
  title: string;
  data: RowItem[];
  sum: string;
  columns: string[];
  width?: string | undefined;
  isIrk?: boolean;
}

const IntegralIndicators: React.FC<IntegralIndicatorsProps> = (props) => {
  const { title, data, sum, columns, isIrk } = props;

  const handleClassName = (item: any): string => {
    if (item === 'Суммарно') {
      return styles.headerColumn;
    } else if (item === 'AOG') {
      return styles.headerColumnRed;
    } else if (item === 'Critical') {
      return styles.headerColumnBlue;
    } else if (item === 'Routine') {
      return styles.headerColumnGreen;
    } else if (item === 'Work Stoppage' || item === 'Work stoppage') {
      return styles.headerColumnPurple;
    } else {
      return styles.headerColumn;
    }
  };

  const formatValues = (value: any, percent: any): string => {
    if (MiscUtils.isBlankString(percent) && MiscUtils.isBlankString(value)) {
      return ``;
    } else if (percent === "" && process.env.REACT_APP_TYPE === 'csdp') {
      return `${value}`
    } else if (isZeroValue(value) && isZeroValue(percent) && process.env.REACT_APP_TYPE === 'csdp') {
      return '-';
    } else if (isZeroValue(value) && process.env.REACT_APP_TYPE === 'csdp') {
      value = '-';
    } else if (isZeroValue(percent) && process.env.REACT_APP_TYPE === 'csdp') {
      percent = '-';
    } else if (MiscUtils.isBlankString(value) && !MiscUtils.isBlankString(percent)) {
      return `${percent}`;
    } else if (MiscUtils.isBlankString(percent) && !MiscUtils.isBlankString(value)) {
      return `${value}`;
    }
    return `${value}/${percent}`;
  };

  const formatTime = (timeVal: string) => {
    if (timeVal.includes('/')) {
        let [firstHalf, secondHalf] = timeVal.split('/');
        if (firstHalf === '0:00' && secondHalf === '0:00') return '-';
        return `${firstHalf === '0:00' ? '-' : firstHalf}/${secondHalf === '0:00' ? '-' : secondHalf}`
    } else {
        return timeVal;
    }
  }

  return (
    <div className={styles.wrapper} style={props.width ? { width: props.width } : {}}>
      <div className={styles.wrapperTitle}>
        <div className={styles.title}>{title}</div>
        <div className={styles.sum}>{sum}</div>
      </div>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.header}>
            <th />
            {columns.map((column: any, i: number) => {
              return (
                <th key={i} className={handleClassName(column)}>
                  {column}
                </th>
              );
            })}
          </tr>
          {data?.map((item: any, a: number) => {
            return (
              <tr className={styles.row} key={`item_${a}`}>
                <td className={styles.headerRow}>{isIrk ? item?.label : item?.label === "Просроченные" ? "Плановый срок истек" : item?.label}</td>
                {!!item.values && item.values.map((value: any, i: number) => {
                  if (item?.percent) {
                    return (
                      <td className={styles.cell} key={`value_${i}`}>
                        {formatValues(value, item?.percent[i])}{' '}
                      </td>
                    );
                  } else {
                    return (
                      <td className={styles.cell} key={`value_${i}`}>
                        {process.env.REACT_APP_TYPE === 'csdp' ? formatTime(value) : value}{' '}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IntegralIndicators;

import { useWindowWidth } from '@react-hook/window-size';
import {
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  LinearScale,
  Tick,
} from 'chart.js';
import { handleFontSize } from 'pages/reports/RequestsChart';
import React, { useCallback, useEffect, useState } from 'react';
import FormatUtils from 'utils/FormatUtils';
import { KpiData, KPIType } from './Kpi';
import { externalTooltipHandler } from './utils/KpiTooltipUtils';
import styles from './KpiChart.module.css';
import dataLabels from 'chartjs-plugin-datalabels';

interface KpiChartProps {
  kpiData: KpiData;
  targetKpi: string;
  width: number;
  isRA?: boolean;
}

const KpiChart: React.FC<KpiChartProps> = (props) => {
  const { kpiData, targetKpi, width, isRA } = props;
  const { kpiType, diffDays, data } = kpiData || {};
  const [chartCanvas, setChartCanvas] = useState<any>();
  const [chart, setChart] = useState<Chart>();
  const [chartData, setChartData] = useState<ChartData>();

  const style = getComputedStyle(document.body);
  const colorDark = style.getPropertyValue('--color-dark')
  const colorBright6 = style.getPropertyValue('--color-bright-6');
  const colorBright10 = style.getPropertyValue('--color-bright-10');

  const ticksCallback = (_: any, index: any, ticks: any) => {
    const tick: Tick = ticks[index];
    if (kpiType === KPIType.MINUTES) {
      return FormatUtils.convertMinsToHrsMins(tick.value);
    }
    return tick.value;
  };

  const handleMaxSize = (time: string) => {
    const hour: number = Number(time.split(':', 1)[0]) + 2;
    return hour * 60;
  };

  useEffect(() => {
    if (chart && data && kpiType && diffDays) {
      const convertData = (data: any[], kpiType: KPIType) => {
        const labels: any[] = [];
        const newData: number[] = data.reduce((result: any[], current: any) => {
          const label: string = FormatUtils.formatDatesRagne(current.time_period, diffDays);
          labels.push(label);
          result.push(convertDataByType(kpiType, current.all));
          return result;
        }, []);
        const datasets: any[] = [
          {
            label: '',
            type: 'bar',
            data: newData,
            borderColor: colorBright10,
            backgroundColor: colorBright10,
          },
        ];
        return { datasets, labels };
      };
      setChartData({ ...convertData(data, kpiType) });
    }
  }, [data, diffDays, chart, kpiType]);

  const handleWidthBorder = (): number => {
    if (width >= 2560) {
      return 3;
    } else if (width <= 2559 && width >= 1950) {
      return 3;
    } else if (width <= 1949 && width >= 1500) {
      return 2;
    } else if (width <= 1499 && width >= 200) {
      return 1;
    } else {
      return 1;
    }
  };

  let tooltipTimeout: NodeJS.Timeout | undefined;

  const initChart = useCallback(
    (canvas) => {
      if (canvas !== null) {
        chart?.destroy();
        Chart.register([
          BarController,
          BarElement,
          CategoryScale,
          LinearScale,
          LineController,
          PointElement,
          LineElement,
          Tooltip,
        ]);
        setChart(
          new Chart(canvas, {
            type: 'bar',
            data: { datasets: [] },
            plugins: [
              {
                id: 'customPlugin',
                afterDraw: (chart, args, options) => {
                  const {
                    ctx,
                    config,
                    scales: { x, y },
                  } = chart;
                  ctx.beginPath();
                  ctx.beginPath();
                  ctx.moveTo(chart.chartArea.left, y.getPixelForValue(Number(targetKpi)));
                  ctx.lineTo(canvas.width, y.getPixelForValue(Number(targetKpi)));
                  ctx.strokeStyle = colorBright6;
                  ctx.lineWidth = handleWidthBorder();
                  ctx.setLineDash([10, 10]);
                  ctx.stroke();
                },
              },
            ],
            options: {
              interaction: {
                intersect: false,
              },
              responsive: true,
              scales: {
                y: {
                  grace: 20,
                  ticks: {
                    callback: ticksCallback,
                    stepSize: 12.5,
                    font: {
                      size: handleFontSize(width),
                    },
                  },
                  max: 100,
                  min: 0,
                },
                x: {
                  ticks: {
                    font: {
                      size: handleFontSize(width),
                    },
                    color: colorDark,
                  },
                },
              },
              plugins: {
                datalabels: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                  position: 'nearest',
                  callbacks: {
                    label(context) {
                      const { label } = context;
                      return [label, JSON.stringify(data), kpiType];
                    },
                    
                  },
                  external: externalTooltipHandler(isRA, tooltipTimeout).bind(this),
                },
              },
            },
          })
        );
        setChartCanvas(canvas);
      }
    },
    [targetKpi]
  );

  const convertDataByType = (type: KPIType, value: string) => {
    if (type === KPIType.MINUTES) {
      const time: string[] = value.split(':');
      const hours: number = parseInt(time[0], 10) * 60;
      const minutes: number = parseInt(time[1], 10);
      return hours + minutes;
    }
    return FormatUtils.formatNumberNN(value);
  };

  useEffect(() => {
    if (chartCanvas && chart && chartData) {
      chart.data = chartData;
      if (
        chart.options &&
        chart.options.scales &&
        chart.options.scales.y &&
        chart.options.scales.y.max &&
        chart.options.scales.y.ticks
      ) {
        chart.options.scales.y.ticks.callback = ticksCallback;
        let stepSize: number = 12.5;
        let maxSize: any = 100;
        if (data && data.length > 0) {
          const target: string[] = `${data[0].target}`.split(':');
          if (target.length === 2) {
            const hours: number = parseInt(target[0], 10);
            maxSize = handleMaxSize(targetKpi);
            stepSize = 30;
          }
        }
        (chart.options.scales.y.ticks as any).stepSize = stepSize;
        (chart.options.scales.y as any).max = maxSize;
        if (chart.options.plugins?.tooltip) {
            chart.options.plugins.tooltip = {
                enabled: false,
                position: 'nearest',
                callbacks: {
                label(context) {
                    const { label } = context;
                    return [label, JSON.stringify(data), kpiType];
                },
                
                },
                external: externalTooltipHandler(isRA, tooltipTimeout).bind(this),
            }
        }
      }
      chart.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, chartData, kpiType, chartCanvas, width]);

  return (
    <>
      <canvas ref={initChart} id='canvas' className={styles.chart} />
    </>
  );
};

export default KpiChart;

import { TFunction } from 'i18next';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { valueForPeriod } from 'pages/reports/utils';

export const indicatorKpi = (t: any) => {
  return {
    choiceItems: {
      percentIndicator: {
        index: 'percentIndicator',
        label: t('percentageOfRequests'),
        params: {
          ChoiceCode: 'percentIndicator',
          Title: t('percentageOfRequests'),
          ObjectState: 'ALIVE',
        },
      },
      timeIndicator: {
        index: 'timeIndicator',
        label: t('requestProcessingTimeNotMinute'),
        params: {
          ChoiceCode: 'timeIndicator',
          Title: t('requestProcessingTimeNotMinute'),
          ObjectState: 'ALIVE',
        },
      }
    }
  }
};

export const indicatorKpiRA = (t: TFunction<"translation", undefined>) => ({
  choiceItems: {
    percentIndicator: {
      index: 'percentIndicator',
      label: t('percentageOfRA'),
      params: {
        ChoiceCode: 'percentIndicator',
        Title: t('percentageOfRA'),
        ObjectState: 'ALIVE',
      },
    },
    timeIndicator: {
      index: 'timeIndicator',
      label: t('RAProcessingTimeNotMinute'),
      params: {
        ChoiceCode: 'timeIndicator',
        Title: t('RAProcessingTimeNotMinute'),
        ObjectState: 'ALIVE',
      },
    }
  }
});

export const periodText = (t: any) => {
  return {
    choiceItems: {
      currentMonth: {
        index: 'currentMonth',
        label: t('currentMonth'),
        params: {
          ChoiceCode: 'currentMonth',
          Title: t('currentMonth'),
          ObjectState: 'ALIVE',
        },
      },
      lastMonth: {
        index: 'lastMonth',
        label: t('previousMonth'),
        params: {
          ChoiceCode: 'lastMonth',
          Title: t('previousMonth'),
          ObjectState: 'ALIVE',
        },
      },
      currentQuarter: {
        index: 'currentQuarter',
        label: t('currentQuarter'),
        params: {
          ChoiceCode: 'currentQuarter',
          Title: t('currentQuarter'),
          ObjectState: 'ALIVE',
        },
      },
      lastQuarter: {
        index: 'lastQuarter',
        label: t('previousQuarter'),
        params: {
          ChoiceCode: 'lastQuarter',
          Title: t('previousQuarter'),
          ObjectState: 'ALIVE',
        },
      },
      currentSemester: {
        index: 'currentSemester',
        label: t('currentHalfAYear'),
        params: {
          ChoiceCode: 'currentSemester',
          Title: t('currentHalfAYear'),
          ObjectState: 'ALIVE',
        },
      },
      lastSemester: {
        index: 'lastSemester',
        label: t('previousHalfAYear'),
        params: {
          ChoiceCode: 'lastSemester',
          Title: t('previousHalfAYear'),
          ObjectState: 'ALIVE',
        },
      },
      currentYear: {
        index: 'currentYear',
        label: t('currentYear'),
        params: {
          ChoiceCode: 'currentYear',
          Title: t('currentYear'),
          ObjectState: 'ALIVE',
        },
      },
      lastYear: {
        index: 'lastYear',
        label: t('previousYear'),
        params: {
          ChoiceCode: 'lastYear',
          Title: t('previousYear'),
          ObjectState: 'ALIVE',
        },
      },
      arbitraryPeriod: {
        index: 'arbitraryPeriod',
        label: t('customPeriod'),
        params: {
          ChoiceCode: 'arbitraryPeriod',
          Title: t('customPeriod'),
          ObjectState: 'ALIVE',
        },
      }
    }
  }
};


export const layout = (t: any, isRA?: boolean,) => ({
  id: '',
  name: 'VirtualChoiceList',
  panels: [
    {
      componentsRows: [
        {
          components: [
            {
              renderer: 'select',
              virtual: true,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('KPIParameter'),
              system: false,
              choiceName: 'indicatorKpi',
              cssClass: 'span3',
              label: t('KPIParameter'),
              params: {},
              propName: 'indicatorKpi',
              propertyType: 'string',
              templateName: 'select',
              value: 'percentIndicator',
            },
            {
              renderer: 'input',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('targetKPI'),
              system: false,
              choiceName: 'targetKpi',
              cssClass: 'span3',
              label: t('targetKPI'),
              params: {},
              propName: 'targetKpi',
              propertyType: 'STRING',
              templateName: 'input',
              defaultValueRaw: '85',
              masked: true,
            },
            {
              renderer: 'select',
              virtual: true,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('period'),
              system: false,
              choiceName: 'periodText',
              cssClass: 'span3',
              label: t('period'),
              params: {},
              propName: 'periodText',
              propertyType: 'string',
              templateName: 'select',
              value: 'currentMonth',
            },
            {
              renderer: 'betweenDatePicker',
              virtual: true,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: true,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: '',
              system: false,
              choiceName: 'periodData',
              cssClass: 'span3',
              label: '',
              params: {},
              propName: 'periodData',
              propertyType: 'string',
              templateName: 'betweenDatePicker',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {
                ...(process.env.REACT_APP_TYPE !== 'irk' && {"parametrized": {    "parameters": {"AircraftType": "AircraftType"},    "required": []  }})
              },
              ObjectState: 'ALIVE',
              readonly: process.env.REACT_APP_TYPE === 'irk' ? true : false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('department'),
              system: false,
              cssClass: 'span3',
              label: t('department'),
              params: {},
              propName: 'department_',
              propertyType: 'string',
              templateName: 'select',
              choiceName: process.env.REACT_APP_TYPE === 'irk' ? 'OwnerGroup' :'OwnerGroupAvailableForKPI',
              defaultValueRaw: process.env.REACT_APP_TYPE === 'irk' ? 'ОСЦ' : '',
            },
            {
              renderer: 'input',
              virtual: true,
              hidden: true,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: true,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('department'),
              system: false,
              choiceName: 'department',
              cssClass: 'span3',
              label: t('department'),
              params: {},
              propName: 'department',
              propertyType: 'string',
              templateName: 'input',
              defaultValueRaw: process.env.REACT_APP_TYPE === 'irk' ? 'ОСЦ' : '',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('executor'),
              system: false,
              choiceName: 'OwnerForKPI',
              cssClass: 'span3',
              label: t('executor'),
              params: {},
              propName: 'ownerKpi',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: isRA,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('priority'),
              system: false,
              choiceName: 'InternalPriority',
              cssClass: 'span3',
              label: t('priority'),
              params: {},
              propName: 'priority',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: false,
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: process.env.REACT_APP_TYPE === 'irk' ? 'Контрагент' : t('customer'),
              system: false,
              choiceName: 'OrganizationList',
              cssClass: 'span3',
              label: process.env.REACT_APP_TYPE === 'irk' ? 'Контрагент' : t('customer'),
              params: {},
              propName: 'organizationList',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'input',
              virtual: true,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: true,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Тип запроса',
              system: false,
              choiceName: 'employee',
              cssClass: 'span3',
              label: 'Тип запроса',
              params: {},
              propName: 'employee',
              propertyType: 'string',
              templateName: 'input',
              defaultValueRaw: 'Инженерно-технический',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Тип ВС',
              system: false,
              choiceName: process.env.REACT_APP_TYPE === 'irk' ? 'Aircraft_TypeList' : 'ProductType',
              cssClass: 'span3',
              label: 'Тип ВС',
              params: {},
              propName: process.env.REACT_APP_TYPE === 'irk' ? 'aircraftType' : 'ProductType',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'csdp',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('category'),
              system: false,
              choiceName: isRA ? 'ComplaintType' : 'RequestType',
              cssClass: 'span3',
              label: t('category'),
              params: {},
              propName: 'requestType',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'csdp',
              isUnique: false,
              required: false,
              jsonConfig: {},
              ObjectState: 'ALIVE',
              readonly: process.env.REACT_APP_TYPE === 'csdp' && isRA,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: t('engineType'),
              system: false,
              choiceName: process.env.REACT_APP_TYPE === 'irk' ? '' : 'ProductType',
              cssClass: 'span3',
              label: t('engineType'),
              params: {},
              propName: process.env.REACT_APP_TYPE === 'irk' ? '' : 'ProductType',
              propertyType: 'string',
              templateName: 'select',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: JSON.stringify({
                parametrized: {
                  parameters: {
                    AircraftType: 'aircraftType',
                  },
                  required: ['AircraftType'],
                },
              }),
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Модель ВС',
              system: false,
              choiceName: 'AircraftModelByType',
              cssClass: 'span3',
              label: 'Модель ВС',
              params: {},
              propName: 'aircraftModel',
              propertyType: 'string',
              templateName: 'dynamicSelect',
            },
            {
              renderer: 'select',
              virtual: false,
              hidden: process.env.REACT_APP_TYPE !== 'irk',
              isUnique: false,
              required: false,
              jsonConfig: JSON.stringify({
                parametrized: {
                  parameters: {
                    AircraftType: 'aircraftType',
                  },
                  required: ['AircraftType'],
                },
              }),
              ObjectState: 'ALIVE',
              readonly: false,
              ClassName: 'property_description',
              dataType: 'STRING',
              Title: 'Система ВС',
              system: false,
              choiceName: 'ATA_ByAircraftType',
              cssClass: 'span3',
              label: 'Система ВС',
              params: {},
              propName: 'ataAircraftType',
              propertyType: 'string',
              templateName: 'select',
            },
          ],
        },
      ],
      expanded: true,
      legend: t('mainInformation'),
    },
  ],
  params: {
    Title: t('mainInformation'),
  },
  readonly: false,
});

export function getDateValueForPeriod(period: any, t:any) {
  const values: any[] = valueForPeriod(t).filter((item) => {
    return item.value === period;
  });
  if (values.length === 1) {
    const value: any = values[0];
    if (typeof value.date === 'function') {
      return value.date();
    }
    return value.date;
  }
  return [];
}

import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { Button, Checkbox, DatePicker, Input, Form, FormInstance, List } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import VirtualList from 'rc-virtual-list';
import axios from 'axios';
import { cloneDeep, isArray, uniqueId } from 'lodash';

import SearchIcon from 'components/icons/SearchIcon';
import DeleteIcon from 'components/icons/table/DeleteIcon';
import FilterSearchIcon from 'components/icons/FilterSearchIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
import notification from 'components/messages/notification';

import styles from './FiltersTooltip.module.css';
import stylesEntityList from 'pages/entityList/EntityList.module.css';
import stylesInputDate from 'components/controls/InputDate/InputDate.module.css';
import checkboxStyle from 'components/controls/Checkbox/InputCheckbox.module.css';

import { FiltersConfig } from 'pages/entityList/entityListFilters/EntityListFilters';
import FormatUtils from 'utils/FormatUtils';
import { FilterCheckbox } from 'utils/types';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';
import { getFilters } from 'store/selectors/filters';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { TableField } from 'interfaces/EntityList';
import { useTranslation } from 'react-i18next';

export interface SearchProps {
  field: TableField;
  filtersConfiguration: any;
  tableId?: string;
  propsTooltip: any;
  filtersState?: any | undefined;
  allFilters: FormInstance;
  storeChoiceLists?: any;
  checkedFilters?: any;
}

const FiltersTooltip: React.FC<SearchProps> = (props) => {
  const {
    field,
    filtersConfiguration,
    tableId,
    propsTooltip,
    allFilters,
    filtersState,
    storeChoiceLists,
  } = props;
  const [selectedKeys, setSelectedKeys] = useState<string[] | string>([]);
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [offset, setOffset] = useState<number>(10);
  const [phrase, setPhrase] = useState<any>();

  const {t} = useTranslation();

  const limit = 5;
  const optionsByCheckbox = [
    {
      index: true,
      label: t("yes"),
      checked: false,
    },
    {
      index: false,
      label: t("no"),
      checked: false,
    },
  ];

  const fil =
    field.renderer !== 'loadMultiData'
      ? field.renderer === 'checkbox'
        ? optionsByCheckbox
        : field.renderer === 'multiselect' ||
          field.renderer === 'select' ||
          field.renderer === 'choiceIcon'
        ? Object.values(storeChoiceLists[`${field.choiceName}`]?.choiceItems).slice(0, 10)
        : []
      : [];
  const [filters, setFilters] = useState<any>(fil);
  const [checked, setChecked] = useState<boolean>(false);
  const width = useWindowWidth();
  const ref = React.createRef();
  const dispatch = useDispatch();
  const [form] = useForm<FormInstance>();
  const { RangePicker } = DatePicker;

  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (
      field.renderer === 'multiselect' ||
      field.renderer === 'loadMultiData' ||
      field.renderer === 'select' ||
      field.renderer === 'choiceIcon'
    ) {
      if (target.value === '') {
        const res = fil.map((item: any) => {
          return {
            index: item.index,
            label: item.label,
            checked,
          };
        });
        setFilters(res);
        setIsAutocomplete(false);
      } else {
        setPhrase(target.value);

        handleAutocomplete(target.value, 0, 10);
      }
    } else {
      setSelectedKeys(target.value);
    }
  };

  const handleDateChange = (date: any) => {
    setSelectedKeys(date);
  };

  const handleAutocomplete = (data: any, offset: any, limit: any) => {
    const body: FormData = new FormData();
    body.append('phrase', `${data}`);
    body.append('choiceName', `${field.choiceName}`);
    body.append('limit', `${limit}`);
    body.append('offset', `${offset}`);
    axios.post(`/SPChoice/autocomplete`, body).then((response: AxiosResponseExt) => {
      if (!response.error) {
        const newValues: any[] = Object.values(response.data).map((d: any) => {
          return {
            label: d.label,
            index: d.index,
            checked,
          };
        });
        if (offset === 0) {
          setFilters(newValues);
          setOffset(offset + 10);
        } else {
          setFilters(filters.concat(newValues));
          // setOffset(offset + 5);
        }
        setIsAutocomplete(true);
      } else {
        notification.error({
          text: response.error,
          width,
        });
      }
    });
  };

  const resetFilters = () => {
    setIsAutocomplete(false);
    allFilters.setFieldsValue({ [field.fieldName]: null });
    setOffset(0);
    handleAllCheck(false);
    setSelectedKeys([]);
    propsTooltip.setSelectedKeys(null);
    setChecked(false);
    form.resetFields();
    setFilters(fil);
    dispatch({
      type: ActionType.IS_CHECKED_FILTERS,
      payload: {
        reset: true,
      },
    });
  };

  const handleSearch = (data: string[] | null | string) => {
    propsTooltip.setSelectedKeys(data);
    propsTooltip.confirm();
    if (!filtersConfiguration) return;
    let filters;
    if (data === null) {
      allFilters.setFieldsValue({ [field.fieldName]: data });
      resetFilters();
      filters = { [field.fieldName]: data };
    } else if (checked) {
      filters = { [field.fieldName]: isAutocomplete ? data : null, isAllChecked: checked };
    } else {
      filters = allFilters.getFieldsValue(true);
    }
    dispatch({
      type: ActionType.UPDATE_FILTERS,
      payload: {
        filtersId: tableId || filtersConfiguration.className,
        filters,
        reset: false,
      },
    });
  };

  const handleAllCheck = (data: boolean) => {
    let res = [];
    setFilters(
      filters.map((item: FilterCheckbox) => {
        return {
          index: item.index,
          label: item.label,
          checked: data,
        };
      })
    );
    if (data) {
      res = filters.map((item: FilterCheckbox) => item.index);
    }
    setSelectedKeys(res);
  };

  useEffect(() => {
    allFilters.setFieldsValue({ [field.fieldName]: selectedKeys });
  }, [selectedKeys]);

  useEffect(() => {
    if (field.renderer === 'checkbox') {
      setFilters(optionsByCheckbox);
    }
  }, [field]);

  useEffect(() => {
    if (filtersState !== undefined && filtersState.reset) {
      setOffset(10);
      if (selectedKeys !== undefined && selectedKeys.length > 0) {
        handleSearch(null);
      }
      form.resetFields();
      setIsAutocomplete(false);
      setFilters(
        fil.map((item: any) => {
          return {
            index: item.index,
            label: item.label,
            checked: false,
          };
        })
      );
    }
  }, [filtersState]);

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    if (
      selectedKeys !== undefined &&
      filtersState !== undefined &&
      filtersState.filters !== undefined &&
      filtersState.filters[field.fieldName]
    ) {
      propsTooltip.setSelectedKeys(selectedKeys);
    }
  }, [filtersState]);

  const loadMoreData = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === getHeightFiltersContainer() ||
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - getHeightFiltersContainer() < 4
    ) {
      if (!isAutocomplete) {
        const cloneChoice = cloneDeep(storeChoiceLists[`${field.choiceName}`]?.choiceItems);
        const choice = Object.values(cloneChoice);
        const data = choice.slice(offset, offset + 5);
        const res = data.map((item: any) => {
          return {
            index: item.index,
            label: item.label,
            checked,
          };
        });
        setFilters(filters.concat(res));
        setOffset(offset + 5);
      } else {
        setOffset(offset + 5);
        handleAutocomplete(phrase, offset, limit);
      }
    }
  };

  const getHeightFiltersContainer = () => {
    if (width >= 2560) {
      return 200;
    } else if (width <= 2559 && width >= 1950) {
      return 160;
    } else if (width <= 1949 && width >= 1500) {
      return 130;
    } else if (width <= 1499 && width >= 200) {
      return 100;
    } else {
      return 100;
    }
  };

  useEffect(() => {
    props.filtersConfiguration.config.criteria.conditions.map((item: any) => {
      if (item.field === field.fieldName) {
        dispatch({
          type: ActionType.IS_CHECKED_FILTERS,
          payload: {
            fieldName: field.fieldName,
            checked,
            condition: item.condition.name,
          },
        });
      }
    });
  }, [checked]);

  useEffect(() => {
    setFilters(
      filters.map((item: any) => {
        return {
          index: item.index,
          label: item.label,
          checked: false,
        };
      })
    );
  }, []);

  const ForwardRefCheckbox = React.forwardRef((props: { children: JSX.Element }, ref: any) => (
    <div className={styles.elementList} ref={ref}>
      {' '}
      {props.children}
    </div>
  ));
  return (
    <div>
      <div className={styles.search}>
        <Form form={form}>
          <Form.Item name='value' noStyle={true}>
            {field.renderer === 'dateTimePicker' || field.renderer === 'datePicker' ? (
              <RangePicker
                format={FormatUtils.dateFormat}
                placeholder={['', '']}
                onChange={(date) => handleDateChange(date)}
                suffixIcon={<CalendarIcon className={stylesInputDate.icon} />}
                className={stylesInputDate.input}
              />
            ) : (
              <Input
                onChange={handleChange}
                className={styles.filterInput}
                onPressEnter={() => handleSearch(selectedKeys)}
                prefix={<SearchIcon className={styles.iconSearch} />}
              />
            )}
          </Form.Item>
        </Form>
      </div>
      {filters.length > 0 && (
        <List>
          <div className={styles.filtersList}>
            <Checkbox
              className={checkboxStyle.wrap}
              checked={checked}
              onChange={(e) => {
                handleAllCheck(e.target.checked);
                setChecked(e.target.checked);
              }}
            >
              <span className={styles.listElement}>{t("selectAll")}</span>
            </Checkbox>
            <VirtualList
              data={filters}
              height={getHeightFiltersContainer()}
              itemKey={`index_${uniqueId()}`}
              onScroll={loadMoreData}
            >
              {(item: FilterCheckbox, n: number) => (
                <ForwardRefCheckbox ref={ref} {...item} key={n}>
                  <Checkbox
                    onChange={(e) => {
                      if (isArray(selectedKeys)) {
                        if (e.target.checked) {
                          setSelectedKeys([...selectedKeys, item.index]);
                          item.checked = e.target.checked;
                        } else {
                          setSelectedKeys(selectedKeys.filter((x) => x !== item.index));
                          item.checked = e.target.checked;
                          if (checked && process.env.REACT_APP_TYPE === 'csdp') setChecked(false)
                        }
                      }
                    }}
                    className={checkboxStyle.wrap}
                    checked={item.checked}
                  >
                    {' '}
                    <span className={styles.listElement}>{item.label}</span>
                  </Checkbox>
                </ForwardRefCheckbox>
              )}
            </VirtualList>
          </div>
        </List>
      )}
      <div className={styles.buttonsContainer}>
        <Button
          onClick={() => handleSearch(null)}
          icon={
            <div className={stylesEntityList.iconWrapper}>
              <DeleteIcon className={stylesEntityList.icon} />
            </div>
          }
          className={styles.buttonFilterTooltip}
        >
          {t("reset")}
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys)}
          icon={
            <div className={stylesEntityList.iconWrapper}>
              <FilterSearchIcon className={stylesEntityList.icon} />
            </div>
          }
          className={styles.buttonFilterTooltip}
        >
          {t("apply")}
        </Button>
      </div>
    </div>
  );
};

export default connect((state: AppState, props: SearchProps) => {
  return {
    filtersState: getFilters(state, props.tableId || props.filtersConfiguration.className),
    storeChoiceLists: state.choiceLists,
    checkedFilters: state.checkedFilters,
  };
})(FiltersTooltip);

import styles from './KpiTooltip.module.css';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import FormatUtils from 'utils/FormatUtils';
import { KPIType, renderByKpiType } from '../Kpi';
import { t } from 'i18next';

function getBody(bodyItem: any) {
  return bodyItem.lines;
}

function getFirstDataPoint(dataPoints: any[]) {
  if (dataPoints.length > 0) {
    return dataPoints[0];
  }
  return null;
}

interface TooltipTemplateProps {
  data: any;
  label: string;
  kpiType: KPIType;
  isRA?: boolean;
}

const TooltipTemplate: React.FC<TooltipTemplateProps> = (props) => {
  const { data, label, kpiType, isRA } = props;
  return (
    <div>
      <span className={styles.header}>{label}</span>
      <div className={styles.param}>
        <div className={styles.param__label}>
          <div className={styles.param__label_caption}>
            {kpiType === KPIType.MINUTES ? 'Время обработки, чч:мм' : `${t('finishedOnTime')}, %`}
          </div>
          <div className={styles.param__label_value}>{renderByKpiType(kpiType, data.all)}</div>
        </div>
        {!isRA && (
          <>
            <div className={styles.param__values}>
              <div className={styles.param_label}>AOG</div>
              <div className={styles.param_value}>{renderByKpiType(kpiType, data.aog)}</div>
            </div>
            <div className={styles.param__values}>
              <div className={styles.param_label}>Critical</div>
              <div className={styles.param_value}>{renderByKpiType(kpiType, data.critical)}</div>
            </div>
            {
                data.workStoppage &&
                <div className={styles.param__values}>
                    <div className={styles.param_label}>Work stoppage</div>
                    <div className={styles.param_value}>{renderByKpiType(kpiType, data.workStoppage)}</div>
                </div>
            }
            <div className={styles.param__values}>
              <div className={styles.param_label}>Routine</div>
              <div className={styles.param_value}>{renderByKpiType(kpiType, data.routine)}</div>
            </div>
          </>
        )}
      </div>

      <div className={`${styles.param} ${styles.paramTarget}`}>
        <div className={styles.param__label}>
          <div className={styles.param__label_caption}>
            {kpiType === KPIType.MINUTES ? t('kPIIndicator') : t('kPIIndicatorPercent')}
          </div>
          <div className={styles.param__label_value}>{renderByKpiType(kpiType, data.target)}</div>
        </div>
      </div>
      {
        process.env.REACT_APP_TYPE === 'csdp' &&
        <div className={`${styles.param} ${styles.paramTarget}`}>
            <div className={styles.param__label}>
            <div className={styles.param__label_caption}>
                {t('recievedRequests')}
            </div>
            <div className={styles.param__label_value}>{renderByKpiType(kpiType, String(data.createdRequests))}</div>
            </div>
        </div>
      }
      <div className={styles.param}>
        <div className={styles.param__label}>
          <div className={styles.param__label_caption}>
            {kpiType === KPIType.MINUTES ? t('deviation') : t('deviationPercent')}
          </div>
          <div className={styles.param__label_value}>
            {renderByKpiType(kpiType, data.deviationAll)}
          </div>
        </div>
        {!isRA && (
          <>
            <div className={styles.param__values}>
              <div className={styles.param_label}>AOG</div>
              <div className={styles.param_value}>
                {renderByKpiType(kpiType, data.deviationAOG)}
              </div>
            </div>
            <div className={styles.param__values}>
              <div className={styles.param_label}>Critical</div>
              <div className={styles.param_value}>
                {renderByKpiType(kpiType, data.deviationCritical)}
              </div>
            </div>
            {
              data.deviationWorkStoppage &&
              <div className={styles.param__values}>
                <div className={styles.param_label}>Work stoppage</div>
                <div className={styles.param_value}>
                    {renderByKpiType(kpiType, data.deviationWorkStoppage)}
                </div>
              </div>
            }
            <div className={styles.param__values}>
              <div className={styles.param_label}>Routine</div>
              <div className={styles.param_value}>
                {renderByKpiType(kpiType, data.deviationRoutine)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const externalTooltipHandler =
  (isRA?: boolean, tooltipTimeout?: NodeJS.Timeout) => (context: any) => {
    clearTimeout(tooltipTimeout);

    let { chart, tooltip } = context;

    let tooltipEl: HTMLElement | null = document.getElementById('kpi-chartjs-tooltip');
    if (tooltip.opacity === 0 && tooltipEl) {
      tooltipEl.remove();
      return;
    }
    const drawTooltip = () => {
      let rawData = [];

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'kpi-chartjs-tooltip';
        tooltipEl.classList.add(styles.tooltip);
        tooltipEl.innerHTML = '<div />';
        document.body.appendChild(tooltipEl);
      }

      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      if (tooltip.body) {
        const bodyLines: string[] = tooltip.body.map(getBody);
        let kpiType: KPIType = KPIType.MINUTES;
        if (bodyLines.length === 1 && bodyLines[0].length === 3) {
          try {
            rawData = JSON.parse(bodyLines[0][1]);
          } catch {
            rawData = [];
          }
          kpiType = bodyLines[0][2] as KPIType;
        }
        if (rawData) {
          const dataPoint: any | null = getFirstDataPoint(tooltip.dataPoints);
          if (dataPoint !== null) {
            const { dataIndex, label } = dataPoint;
            const rawDataForIndex: any = rawData[dataIndex];
            if (rawDataForIndex) {
              tooltipEl.innerHTML = ReactDOMServer.renderToString(
                React.createElement(TooltipTemplate, {
                  data: rawDataForIndex,
                  kpiType,
                  label,
                  isRA,
                })
              );
            }
          }
        }
      }

      const position = chart.canvas.getBoundingClientRect();
      const bodyFont = tooltip.options.bodyFont;
      const handleTooltipPosition = (caret: any) => {
        if (window.innerWidth > 1400) {
          const edgeDistance = window.innerWidth - (caret * 1.45 + position.left);
          if (edgeDistance <= 270) {
            return position.left + caret - 270;
          }
          return position.left + tooltip.caretX;
        } else if (window.innerWidth <= 1400 && window.innerWidth >= 1350) {
          const edgeDistance = window.innerWidth - (caret * 1.5 + position.left);
          if (edgeDistance <= 270) {
            return caret - 270 - 270 / 8;
          }
          return position.left + tooltip.caretX;
        } else if (window.innerWidth <= 1349) {
          const edgeDistance = window.innerWidth - (caret * 1.6 + position.left);
          if (edgeDistance <= 270) {
            return caret - 270;
          }
          return position.left + tooltip.caretX;
        }
      };

      tooltipEl.style.opacity = `1`;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = handleTooltipPosition(tooltip.caretX) + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + 250 + 'px';
      tooltipEl.style.font = bodyFont.string;
      tooltipEl.style.padding = tooltip.padding + 'px ' + tooltip.padding + 'px';
      tooltipEl.style.pointerEvents = 'none';
    };

    if (tooltip.opacity !== 0) {
      if (!tooltipEl) {
        tooltipTimeout = setTimeout(() => {
          drawTooltip();
        }, 1000);
      } else {
        drawTooltip();
      }
    }
  };
